@import 'node_modules/@db/db-core/themes/styles.pb.scss';
@import 'node_modules/@db/db-core/themes/styles.db.scss';
$db-root-font-size: 10px;

// custom scs properties
:root {
  --font-size: #{$db-root-font-size};
  --bhw-blue: #0078AD;
  --bhw-blue-800: #3393bd;
  --bhw-blue-600: #66aece;
  --bhw-blue-400: #99c9de;
  --bhw-blue-200: #cce4ef;

  --card-color: #{$db-white};
  --card-text-color: #{$db-white};

  --primary-text-color: #{$db-white};
  --primary-color: var(--bhw-blue-800);
  --primary-button-background: var(--primary-color);
  --primary-text-color: #fff;

  --grey-background-color: #{$db-grey-100};
  --disabled-background: #{$db-grey-200};
  --disabled-text-color: #{$db-grey-400};

  --secondary-color: var(--bhw-blue-200);
  --secondary-button-background: var(--secondary-color);
  --secondary-text-color: var(--primary-color);
  --secondary-hover-background: var(--bhw-blue-400);
  --secondary-hover-text-color: var(--bhw-blue);

  --newhousing-card-color: var(--bhw-blue-600);

  // tertiary buttons on colored area
  --tertiary-background: #fff;
  --tertiary-text-color: var(--primary-color);
  --tertiary-hover-background: var(--bhw-blue-200);
  --tertiary-hover-text-color: var(--primary-color);
  --tertiary-hover-background-2: var(--bhw-blue-200);

  --error-color: #{$db-error};
}

// general
$db-font-size-base: 1.4rem;

// overriding of db-core scss variables for buttons
$db-button-font-size: var(--text-small-size);
$db-button-primary-background-color: var(--primary-button-background);
$db-button-primary-text-color: var(--primary-text-color);
$db-button-primary-background-color-hover: var(--bhw-blue-600);
$db-button-primary-background-color-focus: var(--primary-button-background);
$db-button-primary-background-color-active: var(--primary-button-background);
$db-button-primary-border: none;
$db-button-primary-background-color-disabled: var(--disabled-background);
$db-button-primary-text-color-disabled: var(--disabled-text-color);
$db-button-primary-border-color-disabled: var(--disabled-text-color);

$db-button-secondary-background-color: var(--secondary-button-background);
$db-button-secondary-text-color: var(--secondary-text-color);
$db-button-secondary-background-color-hover: var(--secondary-hover-background);
$db-button-secondary-background-color-focus: var(--secondary-button-background);
$db-button-secondary-background-color-active: var(--secondary-button-background);
$db-button-secondary-text-color-hover: var(--secondary-hover-text-color);
$db-button-secondary-text-color-focus: var(--secondary-text-color);
$db-button-secondary-text-color-active: var(--secondary-text-color);
$db-button-secondary-border: none;

$db-button-tertiary-background-color: var(--tertiary-background);
$db-button-tertiary-background-color-hover: var(--tertiary-hover-background);
$db-button-tertiary-background-color-focus: var(--tertiary-background);
$db-button-tertiary-background-color-active: var(--tertiary-background);
$db-button-tertiary-text-color: var(--tertiary-text-color);
$db-button-tertiary-text-color-hover: var(--tertiary-hover-text-color);
$db-button-tertiary-text-color-focus: var(--tertiary-text-color);
$db-button-tertiary-text-color-active: var(--tertiary-text-color);
$db-button-tertiary-border: none;

$db-button-border-radius: 2px;

// slider db-core variables
$db-slider-height: 10px;
$db-slider-after-background-height: 10px;
$db-slider-fill-background-height: 10px;
$db-slider-fill-border-radius: 2px;
$db-slider-after-border-radius: 2px;
$db-slider-handle-border-radius: 2px;
$db-slider-handle-background: var(--primary-color);
$db-slider-fill-background: var(--bhw-blue-400);

// radio button
$db-radio-button--default__border--selected: 1px solid var(--bhw-blue-200);
$db-radio-button--default__indicator-size: 10px;
$db-radio-button--default__border--disabled: 1px solid var(--disabled-text-color);
$db-radio-button--default__label-color--disabled: var(--disabled-text-color);
$db-radio-button--default__background--disabled: var(--disabled-background);
$db-radio-button--default__indicator-background: var(--primary-color);
$db-radio-button__font-size: var(--text-normal-size);
$db-radio-button--default__border--focus: 1px solid var(--primary-color);

// check box
$db-checkbox-border-radius: 2px;
$db-checkbox-border-color: var(--bhw-blue-200);
$db-checkbox-border-color-checked: var(--bhw-blue-200);
$db-checkbox-border-color-focus: var(--primary-color);
$db-checkbox-icon-color-disabled: var(--disabled-text-color);
$db-checkbox-border-color-disabled: var(--disabled-text-color);

// tabs
// workaround for IE: overriding of db-core varaibles with custom css properties not working with polifills
// if active tab switched later
$db-tabs-position-marker-color: #3393bd;
$db-tabs-link-active-color: #3393bd;
$db-tabs-font-size: var(--text-normal-size);
$db-tabs-background-color: #cce4ef;

// input
$db-input-border: 1px solid var(--bhw-blue-200);
$db-input-border-radius: 2px;
$db-input-field-focus-border: 1px solid var(--primary-color);
$db-input-field-focus-error-border: 1px solid var(--error-color);

// select box
$db-select-border: 1px solid var(--bhw-blue-200);
$db-select-border-radius: 2px;
$db-select-box-shadow: inset 0 0 0 0;
$db-select-focus-box-shadow: 0 10px 30px 0 var(--bhw-blue-200);
$db-select-arrow-fill-color: var(--primary-color);

// swich
$db-switch-bubble-active-background-color: var(--primary-color);
$db-switch-focus-box-shadow: 0px 10px 30px 0px var(--bhw-blue-800);
$db-switch-box-shadow: none;
$db-switch-bubble-inactive-background-color: var(--bhw-blue-200);

// h4
$db-font-family-headings: 'DeutscheBankText';

//list
$db-list-color: $db-grey-700;

// toggleable
$db-toggleable__header--default-background: white;
$db-toggleable-icon-background-color: white;
$db-toggleable-icon-active-background-color: var(--secondary-color);
$db-toggleable-icon--default-active-background-color: var(--secondary-color);
$db-toggleable--default-icon-fill-color: var(--primary-color);

// modal dialog
$db-modal-header-background-color: var(--primary-color);

@import 'node_modules/@db/db-core/themes/styles.pb.scss';

@import 'oap.db-core';

@mixin active-shadow() {
  &:focus,
  &:active {
    box-shadow: 0px 10px 30px 0px var(--bhw-blue-200);
  }
  @include hover-shadow();
}

@mixin active-shadow-dark() {
  &:active,
  &:focus {
    box-shadow: 0px 10px 30px 0px var(--bhw-blue-800);
  }
  @include hover-shadow-dark();
}

@mixin hover-shadow() {
  &:hover {
    box-shadow: 0px 10px 30px 0px var(--bhw-blue-400);
  }
}

@mixin hover-shadow-dark() {
  &:hover {
    box-shadow: 0px 10px 30px 0px var(--bhw-blue);
  }
}

// buttons
.db-button__button {
  font-weight: bold;
  @include active-shadow();
  &.db-button__button--tertiary {
    @include active-shadow-dark();
  }
  &--disabled {
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
}

// slider
.db-slider {
  .db-slider__handle {
    @include hover-shadow();
    &::before {
      content: '\2630';
      font-size: 17px;
      color: var(--bhw-blue-400);
      text-align: center;
      line-height: 22px;
    }
  }
  &:hover {
    .db-slider__handle {
      &::before {
        background-color: var(--bhw-blue-600);
      }
    }
  }
  &:focus {
    .db-slider__handle {
      &::before,
      &::after {
        transform: none;
      }
    }
  }
}

// radio button
.db-radio-group--default {
  // add shadow
  .db-radio-button__input {
    &:checked {
      & + .db-radio-button__content {
        &:hover::before {
          border: 1px solid #3393bd;
          box-shadow: 0px 10px 30px 0px #66aece;
        }
      }
    }
    & + .db-radio-button__content {
      &:hover::before,
      &:focus::before,
      &:active::before {
        border: 1px solid #3393bd;
        box-shadow: 0px 10px 30px 0px #66aece;
      }
    }
  }
}

// check box overridings
db-checkbox {
  .db-checkbox__input {
    margin-top: 12px;
    width: 24px;
    height: 22px;
    border-radius: 2px;
    clip: auto;
    @include hover-shadow();
    &:focus {
      appearance: radio;
    }
    &:checked {
      & + .db-checkbox__content {
        &:hover::before {
          border: 1px solid var(--primary-color);
        }
        .db-checkbox__svg {
          fill: var(--primary-color);
          stroke-width: 0px;
          height: 100%;
          width: 17px;
          margin-left: -1px;
          margin-top: 1px;
        }
      }
    }
  }
}

db-input {
  .db-input__field {
    @include active-shadow();
    &:hover {
      border: 1px solid var(--primary-color);
    }
  }
  &.is-disabled {
    .db-input__field {
      box-shadow: none;
      border-color: #b3b2b2;
      &:hover {
        box-shadow: none;
      }
    }
  }
  &.ng-invalid {
    &.ng-touched {
      .db-input__label::after {
        background: none;
      }
    }
  }
}

db-select {
  &.ng-invalid.ng-touched {
    .db-select__label {
      &::after {
        background: none;
      }
    }
  }

  .db-select__label {
    @include active-shadow();
    border-radius: 2px;
  }
  .db-select__dropdown {
    border: 1px solid var(--bhw-blue-200);
    &:hover,
    &:focus {
      border: 1px solid var(--primary-color);
    }
  }
}

db-switch {
  .db-switch__slider {
    border: 1px solid var(--bhw-blue-400);
    @include active-shadow-dark();
    &::before {
      top: 1px;
      left: 1px;
    }
    &:hover {
      border: 1px solid var(--primary-color);
    }
  }
}
