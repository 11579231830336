@font-face {
  font-family: 'DeutscheBankDisplay';
  src: url('../assets/fonts/DeutscheBankDisplay_W_Rg.eot');
  src: url('../assets/fonts/DeutscheBankDisplay_W_Rg.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/DeutscheBankDisplay_W_Rg.woff2') format('woff2'),
    url('../assets/fonts/DeutscheBankDisplay_W_Rg.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DeutscheBankDisplay';
  src: url('../assets/fonts/DeutscheBankDisplay_W_It.eot');
  src: url('../assets/fonts/DeutscheBankDisplay_W_It.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/DeutscheBankDisplay_W_It.woff2') format('woff2'),
    url('../assets/fonts/DeutscheBankDisplay_W_It.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'DeutscheBankText';
  src: url('../assets/fonts/DeutscheBankText_W_Rg.eot');
  src: url('../assets/fonts/DeutscheBankText_W_Rg.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/DeutscheBankText_W_Rg.woff2') format('woff2'),
    url('../assets/fonts/DeutscheBankText_W_Rg.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DeutscheBankText';
  src: url('../assets/fonts/DeutscheBankText_W_It.eot');
  src: url('../assets/fonts/DeutscheBankText_W_It.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/DeutscheBankText_W_It.woff2') format('woff2'),
    url('../assets/fonts/DeutscheBankText_W_It.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'DeutscheBankIcons';
  src: url('../assets/fonts/db-icon-font.ttf?oml9lu') format('truetype'), url('../assets/fonts/db-icon-font.woff?oml9lu') format('woff');
  font-weight: normal;
  font-style: normal;
}
