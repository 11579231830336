/* You can add global styles to this file, and also import other style files */

@import 'node_modules/@db/db-core/themes/styles.bhw.scss';
@import '../node_modules/flatpickr/dist/flatpickr.css';
@import 'node_modules/@db/db-core/db-docs/styles.docs.scss';
@import 'src/scss/oap-fonts.scss';
@import 'node_modules/@db/db-core/themes/db/_variables.scss';
@import 'node_modules/@db/db-core/themes/styles.db.scss';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/_breakpoints';

:root {
  --text-small-size: 12px;
  --text-normal-size: 14px;
  --text-large-size: 20px;
}

.oap-db-theme {
  @import './scss/oap-db-theme';
}

.oap-bhw-theme {
  @import './scss/oap-bhw-theme';
}

html {
  font-size: var(--font-size);
  overflow-y: scroll;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.oap-user-app {
  // Small devices (landscape phones, less than 768px)
  @include media-breakpoint-down(sm) {
    --container-width: 80%;

    .slider-input {
      display: inline-block;
    }
  }

  // Medium devices (tablets, 768px and up)
  @include media-breakpoint-up(md) {
    --container-width: 70%;

    .slider-input {
      display: none;
    }
  }

  // app layout
  header,
  footer {
    display: block;
  }

  header {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 30%;
  }

  #content {
    top: 30%;
    margin-top: 5px;
    position: fixed;
    width: 100%;
    background: var(--card-color);
    overflow: -moz-scrollbars-vertical;
    overflow-x: auto;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }

  footer {
    width: 100%;
    position: fixed;
    bottom: 0px;
    font-size: var(--text-normal-size);
    color: var(--secondary-text-color);
  }

  // special handling of footer height
  // because of IE11 bug with CSS properties
  .main.reduced {
    #content {
      height: calc(70% - 74px);
    }

    footer {
      height: 74px;
    }

    .form-validation-error {
      top: calc(100% - 74px);
    }
  }

  .main.fulled {
    #content {
      height: calc(70% - 124px - 15px); // additionally 10px => space for error messages between content and footer
      @include media-breakpoint-down(sm) {
        height: calc(70% - 124px - 30px); // additionally 20px => space for multi line error message between content and footer
      }
    }
    footer {
      height: 124px;
    }
    .form-validation-error {
      top: calc(100% - 124px - 10px); // additionally 10px => space for error message between content and footer
      @include media-breakpoint-down(sm) {
        top: calc(100% - 124px - 25px); // additionally 20px => space for multi line error message between content and footer
      }
    }

    @media (max-width: 451px) {
      #content {
        height: calc(70% - 155px - 15px); // additionally 10px => space for error messages between content and footer
        @include media-breakpoint-down(sm) {
          height: calc(70% - 155px - 30px); // additionally 20px => space for multi line error message between content and footer
        }
      }
      footer {
        height: 155px; // additionaly 31px => space for ssl text in 2 lines
      }
      .form-validation-error {
        top: calc(100% - 155px - 10px); // additionally 10px => space for error message between content and footer
        @include media-breakpoint-down(sm) {
          top: calc(100% - 155px - 25px); // additionally 20px => space for multi line error message between content and footer
        }
      }
    }
  }

  * {
    box-sizing: border-box;
    font-family: 'DeutscheBankText';
  }

  a {
    color: var(--primary-color);
  }

  input {
    opacity: 1;
  }

  .row {
    justify-content: space-between;
  }

  form {
    .row {
      justify-content: flex-start;
    }
  }

  .ui-control-label {
    font-size: var(--text-normal-size);
    font-weight: bold;
    line-height: 1.3em;
    padding: 15px 0 10px 0;
  }

  .text-center {
    text-align: center;
  }

  .text-notice {
    padding: 15px 0;
    font-size: var(--text-small-size);
  }

  .ui-control {
    padding-bottom: 20px;
  }

  .bottom-card {
    padding: 10px;
    margin: 10px 0;
    background: var(--secondary-color);
    color: var(--secondary-text-color);
    font-size: var(--text-small-size);
  }

  .container {
    width: var(--container-width);
    padding: 0;
  }

  .popup-content {
    white-space: pre-wrap;
    text-align: center;

    div:first-of-type {
      font-weight: bold;
    }

    .popup-email {
      color: var(--secondary-text-color);
    }
  }

  .form-validation-error {
    position: fixed;
    text-align: center;
    left: 0;
    width: 100%;
    line-height: 1.3;

    &.db-validation-errors__message {
      font-weight: bold;
      font-size: var(--text-normal-size);
    }
  }

  hr {
    border-top: 1px solid var(--primary-color);
  }

  .col-button {
    text-align: right;
  }

  .card {
    overflow: hidden;
    padding: 0;

    p {
      margin: 0;
      color: #fff;
      background: var(--primary-color);
      text-align: center;
      font-weight: 700;
      font-size: var(--text-large-size);
    }

    h4 {
      margin-bottom: 48px;
    }

    h4,
    p {
      padding: 0px 10px;
    }
  }

  // toggle panels styling
  .disable-toggling {
    pointer-events: none;
    cursor: default;
  }

  .enable-error {
    cursor: pointer;

    db-intro-preview {
      border: 1px solid #d05542;
    }
  }
}
