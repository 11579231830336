@import 'oap-fonts';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/_breakpoints';

// header
h4,
.db-heading-4 {
  color: var(--primary-color);
  font-weight: 550;
  margin: 15px 0 15px 0;
  line-height: 1;
}

// button
.db-button__button {
  min-width: 50px;
  text-align: center;
  padding: 8px;
}

.db-tabs__item {
  margin-bottom: 5px;
  height: 60px;
  .db-tabs__link {
    padding: 0;
    margin: 0;
    pointer-events: none;
    text-decoration: none;
    transition: none;
  }
  .db-tabs__link__icon {
    // IE Woraround for overriding: because of db-core styling => '!important' setting is used there
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
    svg {
      height: 24px;
      width: 24px;
    }
  }

  &.is-active-tab {
    border-bottom: 4px solid $db-tabs-position-marker-color;
    background-color: $db-tabs-background-color;
    .db-tabs__link {
      color: $db-tabs-position-marker-color;
    }
  }
  .db-tabs__position-arrow {
    display: none;
  }
  // Small devices (landscape phones, less than 768px)
  // don't show tab title only tab icon
  @include media-breakpoint-down(sm) {
    min-width: 40px;
    height: 40px;
    padding-bottom: 0;
    border-bottom: 2px solid #c3c3c3;
    .db-tabs__link {
      padding-top: 9px;
    }
    .db-tabs__link__icon {
      display: inline-block !important;
    }
    .db-tabs__link__title.d-flex {
      display: none !important;
    }
  }
}

// radio group overriding: vertical middle in row of control
.db-radio-group--default {
  margin-top: 10px;

  .db-radio-button__content {
    font-weight: bold;
  }
  .disabled-radio {
    cursor: not-allowed;
    .db-radio-button__label {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  // Small devices (landscape phones, less than 768px)
  // display radio button one under each other
  @include media-breakpoint-down(sm) {
    db-radio-button {
      display: block;
      margin-right: 0;
    }
  }
}

// checkbox overriding:
db-checkbox {
  &.is-disabled {
    cursor: not-allowed;
    .db-checkbox__label {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .db-checkbox__content {
    margin-top: 13px;
  }
}
// slider overriding: text styling & no right value panel
db-slider {
  .db-slider-right-panel {
    font-size: var(--text-normal-size);
    top: 5px;
  }
  .db-slider {
    .db-slider__range-values {
      top: 25px;
    }
    .db-slider__handle {
      border: none !important;
      background: none !important;
    }
  }
}

//input overriding: no spin buttons & border, color and extension stying
db-input {
  input {
    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }

    &.db-input__field {
      &.db-input__field--extension {
        padding-right: 40px;
      }
    }
  }

  .db-input__extension {
    color: var(--secondary-text-color);
  }
}

// model window stylesheet
.db-modal__container {
  max-width: 80%;

  @include media-breakpoint-up(md) {
    max-width: 60%;
  }

  @include media-breakpoint-up(lg) {
    max-width: 45%;
  }

  @include media-breakpoint-up(xl) {
    max-width: 35%;
  }

  .db-modal-header-default__container {
    .db-modal-header-default__title {
      width: 100%;
      text-align: center;
      padding: 10px;
      color: var(--primary-text-color);
    }
    .db-modal-header-default__button {
      padding: 14px;
      color: var(--primary-text-color);
      .db-modal-header-default__icon {
        height: 1.5rem;
        width: 1.5rem;
        fill: var(--primary-text-color);
        stroke: var(--primary-text-color);
        stroke-width: 6px;
      }
    }
  }
  .error {
    .db-modal-header-default__container {
      background-color: #d05542;
    }
    .popup-button-bar .db-button__button {
      background-color: #d05542;
      border-color: #d05542;
      &:hover {
        background-color: #ba333f;
        border-color: #ba333f;
      }
    }
  }
}

// validation errors
db-validation-errors,
.db-validation-errors__message {
  font-size: var(--text-small-size);
  color: $db-error;
}

// select bos (dropdown choice)
db-select {
  font-weight: normal;
  &.not-selected {
    .db-select__dropdown {
      color: $db-grey-500;
    }
  }
}

// toggle header outline remove
db-intro-preview {
  margin-bottom: 5px;
  padding: 0.5rem;

  .db-toggleable__header {
    outline: none;

    &.is-open.db-toggleable__header--default {
      padding: 0 4px;
      .db-toggleable__label {
        background: var(--secondary-color);
      }
    }
  }
}
