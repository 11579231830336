@import 'node_modules/@db/db-core/themes/styles.pb.scss';
@import 'node_modules/@db/db-core/themes/styles.db.scss';

// custom scs properties
:root {
  --font-size: #{$db-root-font-size};
  --card-color: #{$db-white};
  --card-text-color: #{$db-white};

  --primary-text-color: #{$db-white};
  --primary-color: #{$db-blue-400};
  --primary-button-background: var(--primary-color);
  --primary-text-color: #fff;

  --grey-background-color: #{$db-grey-100};
  --disabled-background: #{$db-grey-200};
  --disabled-text-color: #{$db-grey-400};

  --secondary-color: #{$db-blue-200};
  --secondary-button-background: #fff;
  --secondary-text-color: var(--primary-color);
  --secondary-hover-background: var(--primary-color);
  --secondary-hover-text-color: #fff;

  --newhousing-card-color: #{$db-blue-300};

  // primary buttons on colored area
  --tertiary-background: #fff;
  --tertiary-text-color: var(--primary-color);
  --tertiary-hover-background: var(--newhousing-card-color);
  --tertiary-hover-text-color: #fff;
  --tertiary-hover-background-2: var(--primary-color);
}
// overriding of db-core scss variables for buttons
$db-button-font-size: var(--text-small-size);
$db-button-primary-background-color: var(--primary-button-background);
$db-button-primary-text-color: var(--primary-text-color);
$db-button-primary-background-color-hover: $db-blue-600;
$db-button-primary-background-color-focus: var(--primary-button-background);
$db-button-primary-background-color-active: var(--primary-button-background);
$db-button-primary-border: none;
$db-button-primary-background-color-disabled: var(--disabled-background);
$db-button-primary-text-color-disabled: var(--disabled-text-color);
$db-button-primary-border-color-disabled: var(--disabled-text-color);

$db-button-secondary-background-color: var(--secondary-button-background);
$db-button-secondary-text-color: var(--secondary-text-color);
$db-button-secondary-background-color-hover: var(--secondary-hover-background);
$db-button-secondary-background-color-focus: var(--secondary-button-background);
$db-button-secondary-background-color-active: var(--secondary-button-background);
$db-button-secondary-text-color-hover: var(--secondary-hover-text-color);
$db-button-secondary-text-color-focus: var(--secondary-text-color);
$db-button-secondary-text-color-active: var(--secondary-text-color);
$db-button-secondary-border: 1px solid var(--secondary-text-color);
$db-button-secondary-border-color-hover: var(--primary-color);
$db-button-secondary-border-color-focus: var(--secondary-text-color);

$db-button-tertiary-background-color: var(--tertiary-background);
$db-button-tertiary-background-color-hover: var(--tertiary-hover-background);
$db-button-tertiary-background-color-focus: var(--tertiary-background);
$db-button-tertiary-background-color-active: var(--tertiary-background);
$db-button-tertiary-text-color: var(--tertiary-text-color);
$db-button-tertiary-text-color-hover: var(--tertiary-hover-text-color);
$db-button-tertiary-text-color-focus: var(--tertiary-text-color);
$db-button-tertiary-text-color-active: var(--tertiary-text-color);
$db-button-tertiary-border: 1px solid var(--primary-color);
$db-button-tertiary-border-color-hover: var(--primary-color);

// tabs
$db-tabs-position-marker-color: $db-blue-400;
$db-tabs-link-active-color: $db-blue-400;
$db-tabs-font-size: var(--text-normal-size);
$db-tabs-background-color: $db-blue-100;

// slider
$db-slider-handle-background: var(--primary-color);
$db-slider-fill-background: var(--primary-color);
$db-slider-range-value-font-size: var(--text-normal-size);

// radio button
$db-radio-button--default__indicator-background: var(--primary-color);
$db-radio-button__font-size: var(--text-normal-size);
$db-radio-button--default__border--focus: 1px solid var(--primary-color);

// h4
$db-font-family-headings: 'DeutscheBankText';

// toggleable
$db-toggleable__header--default-background: white;
$db-toggleable-icon-background-color: white;
$db-toggleable-icon-active-background-color: var(--secondary-color);
$db-toggleable-icon--default-active-background-color: var(--secondary-color);
$db-toggleable--default-icon-fill-color: var(--primary-color);

// modal dialog
$db-modal-header-background-color: var(--primary-color);

// input
$db-input-field-focus-border: 1px solid var(--primary-color);
$db-input-disabled-color: $db-grey-500;

@import 'node_modules/@db/db-core/themes/styles.db.scss';
@import 'oap.db-core';

db-slider {
  .db-slider {
    .db-slider__handle {
      &::after,
      &::before {
        border: 1px solid var(--primary-color);
      }
    }
  }
}

.db-radio-group--default {
  .db-radio-button__input:checked {
    & + .db-radio-button__content::before {
      border: 1px solid var(--primary-color);
    }
  }
}

// model window stylesheet
.db-modal-header-default {
  &__container {
    background-color: var(--primary-color);
  }
}
